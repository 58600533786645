import axios from '@/plugins/axios'
import axiosContact from '@/plugins/axios-contact'

export default {

  async list (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`receipts?${params}`)
  },

  async get (id) {
    return await axios.get(`receipts/${id}`)
  },

  async insert (data) {
    return await axios.post('receipts', data)
  },

  async update (id, data) {
    return await axios.put(`receipts/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`receipts/${id}`)
  },

  async download (data) {
    return await axios.post(`receipts/download`, data)
  },

  async downloadContributionDeclaration (data) {
    return await axios.post(`receipts/download-contribution-declaration`, data)
  },

  async downloadContributionDeclarationByContact (data) {
    return await axiosContact.post(`receipts/download-contribution-declaration-by-contact`, data)
  },

  async downloadByContact (data) {
    return await axiosContact.post(`receipts/download-by-contact`, data)
  },

  async downloadTxt (data) {
    return await axios.post(`receipts/download-txt`, data)
  },

}
